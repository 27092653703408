import React, { useEffect, useState, ChangeEvent, ReactElement } from "react";
import { Switch, Typography, Toolbar, SelectChangeEvent } from "@mui/material";
import { Route, useRouteMatch } from "react-router-dom";

import { Search } from "core";
import {
  ClearCacheDialog,
  UserManagementTable,
  UserManagementDialog,
} from "back-office/components";

import { useFetch, FetchMethodEnum, useProfileGetter } from "shared/hooks";

import { EMPTY_STRING } from "shared/constants";
import { GET_USERS } from "back-office/constants";

import { UserType, IResponseGet } from "./types";
import { searchOptions } from "./config";

export const UserManagementPage = (): ReactElement => {
  const { path } = useRouteMatch();
  const [isActiveUserSwitch, setIsActiveUserSwitch] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>(EMPTY_STRING);
  const [searchProperty, setSearchProperty] = useState<string>("username");
  const [users, setUsers] = useState<UserType[]>([]);

  useProfileGetter(0);

  const { data: usersData, refetch } = useFetch<IResponseGet, undefined>(
    GET_USERS,
    {
      method: FetchMethodEnum.GET,
    }
  );

  const handleToggleSwitch = () => {
    setIsActiveUserSwitch((prevState) => !prevState);
  };
  const handleSearchBy = (
    event: SelectChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    setSearchProperty(event.target.value as string);
  };

  const handleInputSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  const handleUpdateUsers = () => {
    refetch();
  };

  // #region Effects
  useEffect(() => {
    if (usersData?.get?.users) {
      const filterUsers = usersData?.get?.users.filter(
        (user: { [key: string]: any }) => {
          if (searchProperty === EMPTY_STRING) {
            return true;
          }
          const filterdUser = user[searchProperty];
          if (typeof filterdUser === "boolean") {
            return filterdUser;
          }
          if (typeof filterdUser === "string") {
            return user[searchProperty]
              .toLowerCase()
              .includes(searchTerm.toLowerCase());
          }
          return false;
        }
      );

      setUsers(
        isActiveUserSwitch
          ? filterUsers.filter((user) => user.isActive)
          : filterUsers
      );
    }
    return () => {};
  }, [isActiveUserSwitch, searchProperty, searchTerm, usersData]);
  // #endregion Effects

  return (
    <>
      <Toolbar>
        <Typography align="left">Show all active users</Typography>
        <Switch
          id="show_activeUsers"
          name="show_activeUsers"
          color="primary"
          onClick={handleToggleSwitch}
          checked={isActiveUserSwitch}
        />
        <Typography align="left">No of Users: {users.length}</Typography>
        <Search
          options={searchOptions}
          searchProperty={searchProperty}
          searchTerm={searchTerm}
          onInputChange={handleInputSearch}
          onChange={handleSearchBy}
        />
      </Toolbar>
      <UserManagementTable users={users} />
      <Route path={`${path}/user-form/:userId?`}>
        {({ match, ...rest }) => {
          const isActive = Boolean(match);
          return (
            isActive && (
              <UserManagementDialog
                onUsersUpdate={handleUpdateUsers}
                users={users}
                {...rest}
              />
            )
          );
        }}
      </Route>
      <Route path={`${path}/clear-cache/:dbType`}>
        {({ match }) => {
          const isActive = Boolean(match);
          return isActive && <ClearCacheDialog />;
        }}
      </Route>
    </>
  );
};

export default UserManagementPage;
